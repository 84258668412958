<template>
  <div>
    <PageHeader title="Zoeken op Hmodh" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <div class="tw-mb-8 tw-py-4 tw-px-6 tw-bg-white tw-rounded tw-shadow-lg">
        <FormulateForm
          :key="searchKey"
          v-model="filters"
          name="contactsSearch"
          debounce
        >
          <div class="tw-flex tw-flex-col md:tw-grid tw-grid-cols-4 xl:tw-grid-cols-6 tw-gap-4">
            <FormulateInput
              type="multiselect"
              name="types"
              label="Pandtypes"
              placeholder="Selecteer types"
              :options="PROPERTY_TYPES"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="select"
              name="status"
              label="Status"
              placeholder="Selecteer status"
              :options="{ 2: 'Te koop', 3: 'Te huur', 4: 'Over te nemen' }"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="autocomplete"
              auto-complete-type="city"
              :multiple="true"
              name="cities"
              label="Plaats"
              placeholder="Selecteer één of meerdere steden"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="number"
              name="price"
              label="Prijs"
              placeholder="Prijs"
              min="0"
              outer-class="tw-m-0"
            />
            <fieldset class="!tw-m-0 fieldset-split-range tw-col-span-2">
              <legend>
                Aanmaakdatum
                <Tooltip class="tw-inline-block">
                  <i class="fas fa-info-circle" />
                  <template #popper>
                    <strong>Zoeken op HMODH gebaseerd op de aanmaakdatum.</strong>
                    <ul class="tw-ml-2.5 tw-list-disc tw-text-xs">
                      <li>Bij het invullen van de eerste datum zal worden gezocht naar alle HMODH's aangemaakt vanaf die datum.</li>
                      <li>Bij het invullen van de laatste datum zal worden gezocht naar alle HMODH's aangemaakt tot die datum.</li>
                      <li>Bij het invullen van beide datums zal worden gezocht naar de aangemaakte HMODH's binnen die periode.</li>
                    </ul>
                  </template>
                </Tooltip>
              </legend>
              <FormulateInput
                type="date"
                name="created_after"
                placeholder="YYYY-MM-DD"
                validation="bail|optional|date:YYYY-MM-DD"
                validation-name="Dit veld"
                outer-class="tw-my-0"
              />
              <FormulateInput
                type="date"
                name="created_before"
                placeholder="YYYY-MM-DD"
                validation="bail|optional|date:YYYY-MM-DD"
                validation-name="Dit veld"
                outer-class="tw-my-0"
              />
            </fieldset>
          </div>

          <details class="tw-my-2" ref="moreFiltersDetails">
            <summary class="tw-text-right tw-font-semibold tw-text-success">
              Meer filters
              <span
                v-if="moreFiltersSelected"
                class="tw-px-1.25 tw-rounded-full tw-bg-success tw-text-white tw-font-semibold tw-text-xs"
              >
                {{ moreFiltersSelected }}
              </span>
            </summary>
            <div class="tw-flex tw-flex-col md:tw-grid tw-grid-cols-4 xl:tw-grid-cols-6 tw-gap-4">
              <FormulateInput
                type="select"
                name="sales_concept"
                label="Concept"
                placeholder="Selecteer concept"
                :options="{ 0: 'Alles', 1: 'Klassiek', 2: 'Presale', 3: 'Countdown', 4: 'Rent To Buy' }"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="manual"
                label="Type HMODH"
                placeholder="Selecteer HMODH-type"
                :options="{ 0: `Alle HMODH's`, 1: `Manuele HMODH's`, 2: `Automatische HMODH's` }"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="surface_trading"
                label="Handelsoppervlakte"
                placeholder="Handelsoppervlakte"
                min="0"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="surface_plot"
                label="Perceelsoppervlakte"
                placeholder="Perceelsoppervlakte"
                min="0"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="terrace_area"
                label="Oppervlakte terras"
                placeholder="Oppervlakte terras"
                min="0"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="number"
                name="floor"
                label="Verdieping"
                placeholder="Verdieping"
                min="0"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="comment_internal"
                label="Commentaar"
                placeholder="Commentaar"
                outer-class="tw-m-0 tw-col-span-2"
              />
              <FormulateInput
                type="checkbox"
                name="epc_labels"
                label="Energielabel"
                :options="EPC_LABELS"
                :input-class="['tw-my-1 tw-w-6']"
                :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-1 tw-items-center']"
                outer-class="tw-m-0 tw-col-span-2"
              />

              <fieldset class="tw-border-none tw-p-0 tw-col-span-2">
                <legend class="formulate-label">
                  Enkel HMODH's voor
                </legend>
                <div class="tw-my-1 tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
                  <FormulateInput
                    type="checkbox"
                    name="is_investment_only"
                    label="Investeringsvastgoed"
                    outer-class="tw-m-0"
                  />
                  <FormulateInput
                    type="checkbox"
                    name="is_newly_built_only"
                    label="Nieuwbouw"
                    outer-class="tw-m-0"
                  />
                  <FormulateInput
                    type="checkbox"
                    name="is_luxury_only"
                    label="Luxevastgoed"
                    outer-class="tw-m-0"
                  />
                  <FormulateInput
                    type="checkbox"
                    name="has_development_potential_only"
                    label="Ontwikkelingspotentieel"
                    outer-class="tw-m-0"
                  />
                </div>
              </fieldset>
            </div>
          </details>

          <div class="tw-my-4 tw-grid tw-col-span-2 sm:tw-grid-flow-col sm:tw-max-w-sm tw-gap-x-6 tw-gap-y-4 tw-text-base tw-text-center">
            <router-link
              :to="{ query: { filters: JSON.stringify(filters) } }"
              class="tw-px-6 tw-py-2 !tw-text-white !tw-no-underline tw-bg-success hover:tw-bg-darken-success tw-rounded-md"
            >
              <i class="tw-mr-2 far fa-search" /> Zoeken
            </router-link>
            <button
              type="button"
              class="tw-px-6 tw-py-2 tw-bg-danger hover:tw-bg-darken-danger tw-rounded-md tw-text-white"
              @click="clearFilters"
            >
              <i class="tw-mr-2 far fa-times" /> Filters wissen
            </button>
          </div>
        </FormulateForm>
      </div>

      <DataTable
        v-show="showDataTable"
        v-model="selectedRecords"
        :loading="loading"
        :headers="headers"
        :can-select="true"
        :infinite-scroll="true"
        :select-all-by-query="true"
        :all-record-ids="allHmodhIds"
        v-bind="hmodhs"
        @allSelected="selectAll"
        @infiniteLoad="infiniteLoad"
        @changedOrder="queryHmodh({ ...$event.params, ...filters })"
      >
        <template #toolbar>
          <div class="tw-flex tw-flex-wrap tw-gap-y-1 tw-justify-end">
            <button
              type="button"
              title="Mailen"
              :disabled="!selectedRecords.length"
              class="action tw-bg-success"
              @click="openMailModal"
            >
              <i class="fas fa-envelope" /> Mailen
            </button>
            <button
              v-if="collaborator && collaborator.hasPerm('EXPORT_CONTACT_GROUP')"
              type="button"
              title="Exporteren"
              :disabled="!(selectedAllByQuery || selectedRecords.length)"
              class="action tw-bg-success"
              @click="openExportModal"
            >
              <i class="fas fa-external-link" /> Exporteren
            </button>
          </div>
        </template>

        <template #item-name="{ item }">
          <router-link v-if="item.contact" :to="{ name: 'ContactDetails', params: { id: item.contact.id } }">
            {{ item.contact.display_name }}
          </router-link>
        </template>
        <template #item-primary_company_link="{ item }">
          <router-link
            v-if="item.contact.primary_company_link"
            :to="{ name: 'ContactDetails', params: { id: item.contact.primary_company_link.id } }"
          >
            {{ item.contact.primary_company_link.display_name }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #item-email="{ item }">
          <a
            v-if="contactMethodExists(item, 'email')"
            :title="item.contact.email"
            :href="`mailto:${item.contact.email}`"
          >
            {{ item.contact.email }}
          </a>
        </template>
        <template #item-phone="{ item }">
          <a
            v-if="contactMethodExists(item, 'phone')"
            :title="item.contact.phone"
            :href="`tel:${item.contact.phone}`"
          >
            {{ item.contact.phone }}
          </a>
        </template>
        <template #item-mobile="{ item }">
          <a
            v-if="contactMethodExists(item, 'mobile')"
            :title="item.contact.mobile"
            :href="`tel:${item.contact.mobile}`"
          >
            {{ item.contact.mobile }}
          </a>
        </template>
        <template #item-contact_score="{ item }">
          <ContactScore
            v-if="item.contact.score"
            :score="item.contact.score"
            :contact-id="item.contact.id"
          />
          <span v-else>-</span>
        </template>
        <template #item-properties_refreshed_on="{ item }">
          {{ item.properties_refreshed_on | datetime }}
        </template>

        <template #item-actions="{ item }">
          <div class="tw-flex">
            <button
              title="Bewerk HMODH"
              class="action tw-bg-warning"
              @click="editHmodh(item)"
            >
              <i class="far fa-pencil" />
            </button>
            <button
              title="Resterende panden"
              class="action tw-bg-primary"
              @click="showHmodhProperties(item)"
            >
              <i class="far fa-eye" />
            </button>
            <button
              title="Archiveer HMODH"
              class="action tw-bg-error"
              @click="archiveHmodh(item)"
            >
              <i class="far fa-archive" />
            </button>
          </div>
        </template>
      </DataTable>
    </div>
    <HmodhPropertiesModal ref="hmodhPropertiesModal" />
    <!-- We do not want to pass any params to queryHmodh hence we use () -->
    <HmodhEditCreate
      ref="editorModal"
      @hmodhUpdated="queryHmodh()"
    />
    <BaseModal ref="exportModal" title="Contacten exporteren" max-width="tw-max-w-sm" @hide="exportForm = {}">
      <FormulateForm
        #default="{ isLoading }"
        v-model="exportForm"
        name="exportForm"
        @submit="exportContacts"
      >
        Er worden contacten van
        <span class="tw-font-semibold">
          {{ selectedAllByQuery ? hmodhs.count : selectedRecords.length }}
          HMODH(s)
        </span>
        geëxporteerd.
        <FormulateInput
          type="text"
          name="reason"
          label="Reden"
          placeholder="Reden"
          validation="required:trim"
        />

        <FormulateErrors />

        <FormulateInput type="submit" :disabled="isLoading" :outer-class="['tw-float-right']">
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-external-link'
            ]"
          />
          Exporteren
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import merge from 'lodash/merge'
import { Tooltip } from 'floating-vue'
import { poll } from '@/utils/helpers'

import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable.vue'

import EventBus from '@/components/iam/bus'

import HmodhCriteria from '@/components/contacts/hmodh/Criteria'
import HmodhEditCreate from '@/components/contacts/hmodh/HmodhEditCreate'
import HmodhPropertiesModal from '@/components/contacts/hmodh/HmodhPropertiesModal'
import ContactScore from '@/components/contacts/ContactScore.vue'

import { PROPERTY_TYPES, EPC_LABELS } from '@/forms/selectOptions'
import { mapGetters } from 'vuex'
import { questionModal, startLoadingModal, successModal, errorModal, stopLoadingModal } from '@/modalMessages'
import { getHmodhs, getHmodhIds, removeHmodh, getHmodhById } from '@/services/hmodh'
import { exportContactsAsCsv, getContactsByIds, pollExportJob } from '@/services/contacts'

export default {
  name: 'HmodhSearch',
  components: {
    Tooltip,
    PageHeader,
    DataTable,
    HmodhEditCreate,
    HmodhPropertiesModal,
    ContactScore
  },
  constants: {
    PROPERTY_TYPES,
    EPC_LABELS,
    HMODH_STATUS_ACTIVE: 1
  },
  data () {
    return {
      searchKey: 'init',
      filters: {},
      loading: false,
      // The object hmodhs includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      hmodhs: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      allHmodhIds: [],
      selectedRecords: [],
      showDataTable: false,
      exportForm: {},
      selectedAllByQuery: false,
      params: {}
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        if (!value.filters) {
          this.showDataTable = false
          return false
        }

        this.showDataTable = true

        const parsedValues = JSON.parse(value.filters)
        const mergedFilters = merge(this.filters, parsedValues)
        this.filters = Object.assign({}, this.filters, mergedFilters)
        this.queryHmodh()
      }
    }
  },
  computed: {
    ...mapGetters('contacts', ['formattedGroups']),
    ...mapGetters('account', ['collaborator']),

    headers () {
      return [
        { value: 'name', text: 'Naam' },
        { value: 'primary_company_link', text: 'Bedrijf' },
        { value: 'email', text: 'E-mail' },
        { value: 'phone', text: 'Telefoon' },
        { value: 'mobile', text: 'GSM' },
        { value: 'contact_score', text: 'Contactscore', orderable: true },
        { value: 'comment_internal', text: 'Commentaar' },
        { value: 'criteria', text: 'Criteria', renderComponent: HmodhCriteria },
        { value: 'properties_refreshed_on', text: 'Laatste matching' },
        { value: 'actions', text: 'Actie' }
      ]
    },
    moreFiltersSelected () {
      const moreFilters = [
        'sales_concept',
        'manual',
        'surface_trading',
        'surface_plot',
        'terrace_area',
        'floor',
        'comment_internal',
        'epc_labels',
        'is_investment_only',
        'is_newly_built_only',
        'is_luxury_only'
      ]
      const selectedMoreFilters = []
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key] && moreFilters.includes(key)) {
          selectedMoreFilters.push(key)
        }
      })
      return selectedMoreFilters.length
    }
  },
  created () {
    if (this.$route.params && typeof this.$route.params === 'object' && !Array.isArray(this.$route.params) && Object.keys(this.$route.params).length) {
      this.filters = this.$route.params
      this.showDataTable = true
      this.queryHmodh()
    }
  },
  mounted () {
    if (this.$route.params && typeof this.$route.params === 'object' && !Array.isArray(this.$route.params) && Object.keys(this.$route.params).length) {
      const details = this.$refs.moreFiltersDetails
      if (details) {
        details.open = !details.open
      }
    }
  },
  methods: {
    openExportModal () {
      this.$refs.exportModal.show()
    },
    hideExportModal () {
      this.$refs.exportModal.hide()
    },
    showHmodhProperties (hmodh) {
      this.$refs.hmodhPropertiesModal.hmodh = hmodh
      this.$refs.hmodhPropertiesModal.show()
    },
    contactMethodExists (hmodh, method) {
      return hmodh.contact?.[method]
    },
    clearFilters () {
      this.searchKey = Math.random() // Reset form and it's values
      this.filters = { hmodh_status: this.HMODH_STATUS_ACTIVE } // set filters to default value
      this.allHmodhIds = []
      this.$router.push({ query: {} }).catch(() => {})

      successModal('Alle filters gewist.')
    },

    parseParams (payload) {
      const { cities, ...params } = payload
      // TODO: rename zipcodes to cities in https://dewaele.atlassian.net/browse/DEW-4402
      if (cities) params.zipcodes = cities.map(city => city.id)
      return params
    },
    selectAll (allSelected) {
      this.selectedAllByQuery = false

      if (!Array.isArray(this.selectedRecords)) {
        // When selecting all items, with the prop select-all-by-query="true" passed to the DataTable,
        // this.selectedRecords returns the params added by the DataTable.
        this.params = { ...this.parseParams(this.filters), ...this.selectedRecords }
        this.selectedAllByQuery = true
        return false
      }

      return allSelected
    },

    async editHmodh (hmodh) {
      try {
        const response = await getHmodhById(hmodh.id)
        this.$refs.editorModal.hmodh = { ...response.data, contact: hmodh.contact }
        this.$refs.editorModal.show()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het laden van de HMODH.')
      }
    },

    async loadHmodhs (payload) {
      const response = await getHmodhs(payload)
      return response.data
    },
    async loadHmodhIds (payload) {
      try {
        const response = await getHmodhIds(payload)
        this.allHmodhIds = response.data
        return response
      } catch (error) {
        errorModal('Fout bij laden van contacts-IDs, selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.hmodhs.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const hmodhs = await this.loadHmodhs({ url: next })
        const results = [...this.hmodhs.results, ...hmodhs.results]
        this.hmodhs = { ...hmodhs, results }
        $infinite.loaded()
        return hmodhs
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async queryHmodh (data = this.filters) {
      try {
        this.loading = true
        const params = this.parseParams(data)
        params.hmodh_status = this.HMODH_STATUS_ACTIVE
        const hmodhs = await this.loadHmodhs({ params })
        this.hmodhs = hmodhs

        // Load ids only when the result count is less than 50
        if (hmodhs.count < 50) await this.loadHmodhIds({ params })
        this.loading = false
        return hmodhs
      } catch (error) {
        errorModal('Fout bij het laden van HMODHs, probeer het opnieuw.')
      }
    },

    async archiveHmodh (hmodh) {
      try {
        const result = await questionModal('Ben je zeker dat je deze HMODH wilt archiveren?')
        if (!result.value) return

        const response = await removeHmodh(hmodh.id)
        await this.queryHmodh()
        return response
      } catch (e) {
        console.error(e)
        await errorModal('Kan hou me op de hoogte niet archiveren. Gelieve opnieuw te proberen.')
      }
    },

    async loadContactsByHmodhIds (hmodh_ids) {
      try {
        startLoadingModal('contacten laden voor HMODH\'s')
        const response = await getContactsByIds({ hmodh_ids })
        const contacts = response.data
        stopLoadingModal()
        return contacts
      } catch (error) {
        errorModal('Fout bij het laden van contacten voor HMODH\'s, probeer het opnieuw.')
      }
    },
    async openMailModal () {
      const contacts = await this.loadContactsByHmodhIds(this.selectedRecords)
      if (contacts.length > 50) return errorModal('Meer dan 50 contacten kunnen niet gemaild worden vanuit HMODH search.')

      const mailclientPayload = {
        contacts: contacts.map(contact => {
          return { type: 'candidate', contact }
        })
      }
      EventBus.$emit('mailclient-prefill-and-show', mailclientPayload)
    },
    async exportContacts (data) {
      try {
        const payload = { ...data }
        if (this.selectedAllByQuery) {
          // Export active HMODHs only
          this.params.hmodh_status = this.HMODH_STATUS_ACTIVE
          payload.hmodh_search_params = this.params
        } else {
          const contacts = await this.loadContactsByHmodhIds(this.selectedRecords)
          payload.contact_ids = contacts.map(contact => contact.id)
        }
        const response = await exportContactsAsCsv(payload)
        const { job_id } = response.data
        const pollResult = await poll(job_id, pollExportJob)

        const win = window.open(pollResult?.url, '_blank')
        if (win) {
          win.focus()
          this.hideExportModal()
          successModal('Records zijn succesvol geëxporteerd.')
        } else errorModal('Sta pop-ups toe om het document te zien.')

        return pollResult
      } catch (error) {
        this.$formulate.handle(error, 'exportForm')
        errorModal('Export werd afgebroken, probeer het opnieuw.')
        console.error(error)
      }
    }
  }
}
</script>
